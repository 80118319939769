import { Modal } from 'antd';
import React from 'react';

const ModalCom = (props) => {
  const { children, title, ...restProps } = props;

  return (
    <Modal title={title} destroyOnClose={true} {...restProps}>
      {children}
    </Modal>
  );
};

export default ModalCom;
