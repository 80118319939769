/*** Base URLs **/
export const IMAGE_BASE_URL = '/media';
export const FILE_UPLOAD = 'uploads/';
export const EULA_ACCEPT = 'eula/accept';

/***
 * Auth APIs
 * **/
export const CONNECT_PORTAL_INFO_BY_ID = 'portal/%s';
export const PORTAL_INFO_BY_UUID = '/portal/uuid/%s';
export const ADMIN_USER_LOGIN = '/portal/root/login/';
export const USER_LOGIN = 'login/';
export const USER_LOGOUT = 'logout/';
export const USER_CLONE = 'me/';
export const CHANGE_PASSWORD = 'me/password/';
export const RESET_PASSWORD_MAIL = 'forgot-password/';
export const RESET_PASSWORD = 'reset-password/';
export const UPDATE_USER = 'me/info';
export const VERIFY_OTP = '2fa/otp/verify';
export const VERIFY_TOTP = '2fa/totp/verify';
export const GENERATE_TOTP_QR = 'me/totp/generate';
export const ADMIN_VERIFY_OTP = '/portal/2fa/otp/verify';
export const ADMIN_VERIFY_TOTP = '/portal/2fa/totp/verify';
export const ADMIN_GENERATE_TOTP_QR = '/portal/root/me/totp/generate';

/***
 * User Management
 */
export const USER_LIST = 'user/list';
export const CREATE_USER_DATA = 'user/create';
export const SINGLE_USER_DATA = 'user/%s';
export const USER_ROLES = 'me/role/managed/list';
export const USER_ALL_ROLES = 'role/list';

/***
 * Manufacturer Management
 */
export const MANUFACTURER_LIST = 'manufacturer/list';
export const MANUFACTURER_CODE_LIST_NEW = 'manufacturer/code/list';
export const MANUFACTURER_SINGLE_DATA = 'manufacturer/%s';
export const FETCH_MISSING_MANUFACTURER_CODE_BATCH =
  'manufacturer/missing-code/%s/list';
export const MANUFACTURER_CODE_LIST = 'compact/manufacturer/code/list';
export const UPDATE_MANUFACTURER_CODE = 'manufacturer/missing-code';
export const UPDATE_MANUFACTURER_CODE_WITH_VOID =
  'manufacturer/missing-code/%s/v2';

/***
 * Sync Connector Management
 */
export const CONNECTOR_TYPE_LIST = 'sync-connector-type/list/v2';
export const CONNECTOR_LIST = 'sync-connector/list';
export const CONNECTOR_SINGLE_DATA = 'sync-connector/%s';

/***
 * Product Categories Management
 */
export const PRODUCT_CATEGORY_LIST = 'product-category/list';
export const PRODUCT_CATEGORY_NEW = 'product-category/create';
export const PRODUCT_CATEGORY_SINGLE_DATA = 'product-category/%s';
export const COMPACT_PRODUCT_CATEGORY_LIST = 'compact/product-category/list';
export const PRODUCT_CATEGORY_LABOR_CATEGORY_MAPPING_LIST =
  'product-category/%s/labor-category/map/list';
export const PRODUCT_CATEGORY_LABOR_CATEGORY_MAP =
  'product-category/%s/labor-category/map';
export const PRODUCT_CATEGORY_SYNC_CONNECTORS_LIST =
  'product-category/%s/connector/map/list';
export const PRODUCT_CATEGORY_DEFAULT_ITEMS = 'item-type/list';

/***
 * Labor Category Management
 */
export const LABOR_CATEGORY_LIST = 'labor-category/list';
export const LABOR_CATEGORY_SINGLE_DATA = 'labor-category/%s';
export const LABOR_CATEGORY_ADD_SINGLE_DATA = 'labor-category/create';

/***
 * Distributor Category Management
 */
export const DISTRIBUTOR_CATEGORY_LIST = 'distributor/category/list';
export const DISTRIBUTOR_CATEGORY_SINGLE_DATA = 'distributor/category/%s';
export const COMPACT_DISTRIBUTOR_LIST = 'compact/distributor/list';
export const COMPACT_DISTRIBUTOR_CATEGORY_LIST =
  'compact/distributor-category/parent/list';
export const COMPACT_DISTRIBUTOR_SUB_CATEGORY_LIST =
  'compact/distributor-category/listByCategoryCode';

/***
 * Catalog Management
 */
export const CATALOG_LIST = 'catalog/list';
export const CATALOG_SINGLE_DATA = 'catalog/%s';
export const CATALOG_NEW = 'catalog/create';
export const CATALOG_DISTRIBUTOR_LIST = 'catalog/%s/distributor/list';
export const CATALOG_DISTRIBUTOR_UPDATE = 'catalog/%s/distributor';
export const CATALOG_LABOR_LIST = 'catalog/%s/labor/list';
export const CATALOG_LABOR_UPDATE = 'catalog/%s/labor';
export const CATALOG_CONNECTOR_LIST = 'catalog/%s/sync-connector/list';
export const CATALOG_CONNECTOR_UPDATE = 'catalog/%s/sync-connector';
export const CATALOG_BULK_CONNECTOR_UPDATE =
  'catalog/sync-connector/bulk-update';
export const SET_CATALOG_PRIMARY_DISTRIBUTOR =
  'catalog/%s/distributor/primary/%s';
export const CATALOG_RECTIFY_BAD_CPN = 'catalog/bad-cpn/rectify';
export const CATALOG_BULK_CONNECTOR_UPDATE_V2 =
  'catalog/sync-connector/bulk-update/v2';
export const UNLOCK_CONNECTOR = 'catalog/%s/sync-connector';

/***
 * Batch Management
 */
export const ALL_BATCH_LIST = 'batch/list';
export const BATCH_SINGLE_DATA = 'batch/%s';
export const VOID_SINGLE_BATCH = 'batch/%s/void';
export const EXPORT_SINGLE_BATCH = 'batch/%s/export';
export const EXPORT_SINGLE_ARCHIVED_BATCH = '/archived-batch/%s/export';
export const ARCHIVED_BATCH_LIST = 'archived-batch/list';
export const BATCH_SINGLE_DATA_LIST = 'batch/%s/item/list';
export const BATCH_SINGLE_DATA_IDS_LIST = 'compact/batch/%s/item/list';
export const BATCH_SINGLE_DATA_NEW = 'batch/%s/new/item/list';
export const BATCH_SINGLE_DATA_DISCONTINUED = 'batch/%s/discontinued/item/list';
export const BATCH_SINGLE_DATA_UPDATED = 'batch/%s/updated/item/list';
export const BATCH_SINGLE_DATA_UNCHANGED = 'batch/%s/unchanged/item/list';
export const ARCHIVED_BATCH_SINGLE_DATA = 'archived-batch/%s/item/list';
export const ARCHIVED_BATCH_SINGLE_DATA_NEW = 'archived-batch/%s/new/item/list';
export const ARCHIVED_BATCH_SINGLE_DATA_UPDATED =
  'archived-batch/%s/updated/item/list';
export const ARCHIVED_BATCH_SINGLE_DATA_UNCHANGED =
  'archived-batch/%s/unchanged/item/list';
export const UPDATE_BATCH_ITEM_SYNC_CONNECTORS = 'batch/%s/sync-connector';
export const UPDATE_BATCH_ITEM_LABOR = 'batch/%s/labor';
export const UPDATE_BATCH_ITEM_EXCLUDE = 'batch/%s/exclude';
export const POST_BATCH = 'batch/%s/post';
export const DUPLICATE_BATCH_ITEM_IDS = 'batch/%s/duplicate-item-id/list';
export const DUPLICATE_BATCH_ITEM_ID_ITEMS =
  'batch/%s/duplicate-item-id/item-id/%s/list';
export const DUPLICATE_BATCH_ITEMS_DELETE = 'batch/%s/duplicate-item-id';
export const BAD_CPN_BATCHES_DATA = 'batch/bad-cpn/list';
export const BAD_CPN_BATCHES_DATA_ITEMS = 'batch/bad-cpn/%s/list';
export const RECTIFY_BAD_CPN_BATCHES = 'batch/bad-cpn/rectify';
/***
 * System
 */
export const ABOUT_SYSTEM = 'about';
export const GENERAL_SYSTEM = 'general';
export const API_KEY_LIST = 'api-key/list';
export const API_KEY_SINGLE_DATA = 'api-key/%s';
export const API_KEY_CREATE_DATA = 'api-key/create';
export const API_KEY_SINGLE_REFRESH = 'api-key/%s/refresh';

export const DISTRIBUTOR_LIST = 'compact/distributor/list';

// Maintenance
export const MAINTENANCE_LIST = 'maintenance/validate-cpn/list';
export const RECTIFY_CPN_LIST = 'maintenance/validate-cpn/rectify';
export const UPDATE_MAINTENANCE_STATUS = 'maintenance/validate-cpn/status';

// Release Notes
export const RELEASE_NOTES_LIST = 'release/notes/list';

// System Sync Connectors Integrations
export const INTEGRATION_TARGET_INFO = 'connector/%s/target-info';
export const INTEGRATION_SYSTEM_OPTIONS = 'connector/%s/system-options/list';
export const INTEGRATION_SYSTEM_OPTIONS_SUBSIDIARY_LIST =
  'connector/%s/quickbooks/ns_subsidiary/list';
export const INTEGRATION_GENERAL_LIST =
  'connector/%s/item-type-id/%s/map-default/list';
export const INTEGRATION_DISTRIBUTOR_LIST =
  'connector/%s/quickbooks-distributor/list';
export const INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST =
  'connector/%s/quickbooks/distributor/list';
export const INTEGRATION_DISTRIBUTOR_CREATE =
  'connector/%s/quickbooks/distributor/create';
export const INTEGRATION_DISTRIBUTOR_MAPPING =
  'connector/%s/quickbooks-distributor/map';
export const INTEGRATION_MANUFACTURER_LIST =
  'connector/%s/quickbooks-manufacturer/list';
export const INTEGRATION_MANUFACTURER_EXPANDED_LIST =
  'manufacturer/list/search-by-code';
export const INTEGRATION_MANUFACTURER_DROPDOWN_LIST =
  'connector/%s/quickbooks/manufacturer/list';
export const INTEGRATION_MANUFACTURER_CREATE =
  'connector/%s/quickbooks/manufacturer/create';
export const INTEGRATION_MANUFACTURER_MAPPING =
  'connector/%s/quickbooks-manufacturer/map';
export const INTGERATION_CATEGORY_LIST = 'connector/%s/category-map/list';
export const INTGERATION_CATEGORY_DROPDOWN_LIST = 'connector/%s/category/list';
export const INTGERATION_CATEGORY_CREATE = 'connector/%s/category/create';
export const INTGERATION_CATEGORY_MAPPING = 'connector/%s/category-map/create';
export const INTGERATION_LABOR_CATEGORY_LIST =
  'connector/%s/labor-category-map/list';
export const INTGERATION_LABOR_CATEGORY_DROPDOWN_LIST =
  'connector/%s/labor-category/list';
export const INTGERATION_LABOR_CATEGORY_CREATE =
  'connector/%s/labor-category/create';
export const INTGERATION_LABOR_CATEGORY_MAPPING =
  'connector/%s/labor-category-map/create';
export const INTEGRATION_ITEM_TYPE_LIST =
  'connector/%s/quickbooks-itemtype/list';
export const INTEGRATION_ITEM_TYPE_DROPDOWN_LIST =
  'connector/%s/quickbooks/itemtype/list';
export const INTGERATION_ITEM_TYPE_MAPPING =
  'connector/%s/quickbooks-itemtype/map';
export const INTEGRATION_GENERAL_EDIT_FIELD_TABLE_COLUMN_DATA =
  'connector/%s/map-default/field/list';
export const INTEGRATION_GENERAL_EDIT_TARGET_DATA =
  'connector/%s/quickbooks/%s/list';
export const INTEGRATION_GENERAL_EDIT_MAP_DEFAULT =
  'connector/%s/item-type-id/%s/map-default';
export const INTEGRATION_GENERAL_EDIT_SALES_TAX_CLASSIFICATION_LIST =
  'connector/%s/tax-classification/list';
export const INTEGRATION_GENERAL_UPDATE_TARGET_FLAG =
  '/connector/%s/item-type-id/%s/map-default/target-update-flag';
export const INTEGRATION_SYSTEM_OPTIONS_UPDATE = 'connector/%s/system-options';
export const INTEGRATION_LOGS_DATA = 'connector/%s/log/list';
export const INTEGRATION_WAREHOUSE_DATA =
  'connector/%s/connector/warehouse/list';
export const INTEGRATION_WAREHOUSES_UPDATE = 'connector/%s/connector/warehouse';
export const INTEGRATION_GENERAL_EDIT_TARGET_UNIT_TYPE =
  'connector/%s/quickbooks/ns_unit_type/%s/%s/list';
