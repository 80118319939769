import { createSlice } from '@reduxjs/toolkit';
import { SYSTEM_SYNC_CONNECTOR_INTEGRATION } from '../reduxConstant';
import { getAPI, postAPI } from '../../utils/apiRequest';
import {
  INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST,
  INTEGRATION_DISTRIBUTOR_LIST,
  INTEGRATION_GENERAL_EDIT_FIELD_TABLE_COLUMN_DATA,
  INTEGRATION_GENERAL_EDIT_SALES_TAX_CLASSIFICATION_LIST,
  INTEGRATION_GENERAL_EDIT_TARGET_DATA,
  INTEGRATION_GENERAL_EDIT_TARGET_UNIT_TYPE,
  INTEGRATION_GENERAL_LIST,
  INTEGRATION_ITEM_TYPE_DROPDOWN_LIST,
  INTEGRATION_ITEM_TYPE_LIST,
  INTEGRATION_LOGS_DATA,
  INTEGRATION_MANUFACTURER_DROPDOWN_LIST,
  INTEGRATION_MANUFACTURER_EXPANDED_LIST,
  INTEGRATION_MANUFACTURER_LIST,
  INTEGRATION_SYSTEM_OPTIONS,
  INTEGRATION_SYSTEM_OPTIONS_SUBSIDIARY_LIST,
  INTEGRATION_TARGET_INFO,
  INTEGRATION_WAREHOUSE_DATA,
  INTGERATION_CATEGORY_DROPDOWN_LIST,
  INTGERATION_CATEGORY_LIST,
  INTGERATION_LABOR_CATEGORY_DROPDOWN_LIST,
  INTGERATION_LABOR_CATEGORY_LIST,
} from '../../constants/api';
import { lowerCase } from 'lodash';
import {
  CATEGORY,
  DISTRIBUTOR,
  INTEGRATION_LIST,
  INTGERATION_DROPDOWN_LIST,
  LABOR_CATEGORY,
  MANUFACTURER,
  ITEM_TYPE,
  LOGS,
  WAREHOUSES,
} from '../../constants/constants';
import { interpolate } from '../../utils/common';
import { changeApiLoader } from './apiLoaderSlice';
import { filterTreeData } from '../../utils/helper';

export const systemSyncConnectorIntegrationSlice = createSlice({
  name: SYSTEM_SYNC_CONNECTOR_INTEGRATION,
  initialState: {
    targetInfo: {},
    systemOptionsData: {},
    subsidiaryList: [],
    generalData: [],
    generalDataObj: {},
    distributorData: {},
    distributorDropdownData: [],
    manufacturerData: {},
    manufacturerExpandedData: {},
    manufacturerDropdownData: [],
    originCategoryData: [],
    categoryData: [],
    categoryDropdownData: [],
    laborCategoryData: [],
    laborCategoryDropdownData: [],
    itemTypeData: [],
    itemTypeDropdownData: [],
    generalEditFieldTableColumnData: [],
    generalEditTargetData: [],
    generalEditSalesTaxClassificationData: [],
    logsData: {},
    warehouseData: [],
  },
  reducers: {
    setTargetInfo: (state, action) => {
      state.targetInfo = action.payload;
    },
    setSystemOptionsData: (state, action) => {
      state.systemOptionsData = action.payload;
    },
    setSubsidiaryList: (state, action) => {
      state.subsidiaryList = action.payload;
    },
    setSyncConnectorGeneralData: (state, action) => {
      state.generalData = action.payload;
      state.generalDataObj = action.payload.reduce((acc, currentVal) => {
        acc[currentVal.field_name] = { ...currentVal };
        return acc;
      }, {});
    },
    setSyncConnectorDistributorData: (state, action) => {
      state.distributorData = action.payload;
    },
    setSyncConnectorDistributorDropdownData: (state, action) => {
      state.distributorDropdownData = action.payload;
    },
    setSyncConnectorManufacturerData: (state, action) => {
      state.manufacturerData = action.payload;
    },
    setManufacturerExpandedData: (state, action) => {
      state.manufacturerExpandedData = {
        ...state.manufacturerExpandedData,
        [action.payload.code]: action.payload.data,
      };
    },
    setSyncConnectorManufacturerDropdownData: (state, action) => {
      state.manufacturerDropdownData = action.payload;
    },
    setOriginCategoryData: (state, action) => {
      state.originCategoryData = action.payload;
    },
    setSyncConnectorCategoryData: (state, action) => {
      state.categoryData = action.payload;
    },
    setSyncConnectorCategoryDropdownData: (state, action) => {
      state.categoryDropdownData = action.payload;
    },
    setSyncConnectorLaborCategoryData: (state, action) => {
      state.laborCategoryData = action.payload;
    },
    setSyncConnectorLaborCategoryDropdownData: (state, action) => {
      state.laborCategoryDropdownData = action.payload;
    },
    setSyncConnectorItemTypeData: (state, action) => {
      state.itemTypeData = action.payload;
    },
    setSyncConnectorItemTypeDropdownData: (state, action) => {
      state.itemTypeDropdownData = action.payload;
    },
    setGeneralEditFieldTableColumnData: (state, action) => {
      state.generalEditFieldTableColumnData = action.payload;
    },
    setGeneralEditTargetData: (state, action) => {
      state.generalEditTargetData = action.payload;
    },
    setGeneralEditSalesTaxClassificationData: (state, action) => {
      state.generalEditSalesTaxClassificationData = action.payload;
    },
    setSyncConnectorLogsData: (state, action) => {
      state.logsData = action.payload;
    },
    setWarehouseData: (state, action) => {
      state.warehouseData = action.payload;
    },
  },
});

const switchListUrls = (tab) => {
  switch (tab) {
    case lowerCase(DISTRIBUTOR):
      return INTEGRATION_DISTRIBUTOR_LIST;
    case lowerCase(MANUFACTURER):
      return INTEGRATION_MANUFACTURER_LIST;
    case lowerCase(CATEGORY):
      return INTGERATION_CATEGORY_LIST;
    case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
      return INTGERATION_LABOR_CATEGORY_LIST;
    case lowerCase(ITEM_TYPE).split(' ').join('-'):
      return INTEGRATION_ITEM_TYPE_LIST;
    case lowerCase(LOGS):
      return INTEGRATION_LOGS_DATA;
    case lowerCase(WAREHOUSES):
      return INTEGRATION_WAREHOUSE_DATA;
    default:
      return INTEGRATION_DISTRIBUTOR_LIST;
  }
};

const switchDropdownListUrls = (tab) => {
  switch (tab) {
    case lowerCase(DISTRIBUTOR):
      return INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST;
    case lowerCase(MANUFACTURER):
      return INTEGRATION_MANUFACTURER_DROPDOWN_LIST;
    case lowerCase(CATEGORY):
      return INTGERATION_CATEGORY_DROPDOWN_LIST;
    case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
      return INTGERATION_LABOR_CATEGORY_DROPDOWN_LIST;
    case lowerCase(ITEM_TYPE).split(' ').join('-'):
      return INTEGRATION_ITEM_TYPE_DROPDOWN_LIST;
    default:
      return INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST;
  }
};

const switchUrls = (type, tab) => {
  switch (type) {
    case INTEGRATION_LIST:
      return switchListUrls(tab);
    case INTGERATION_DROPDOWN_LIST:
      return switchDropdownListUrls(tab);
    default:
      return;
  }
};

const formCategoryTreeData = (data) => {
  return data.map((item) => {
    if (item.children && item.children.length === 0) {
      delete item.children;
    } else if (item.children && item.children.length > 0) {
      item = {
        ...item,
        children: formCategoryTreeData(item.children),
      };
    }
    return item;
  });
};

export const switchListSetFunction = (tab, dispatch, result, filters) => {
  switch (tab) {
    case lowerCase(DISTRIBUTOR):
      dispatch(setSyncConnectorDistributorData(result.data));
      break;
    case lowerCase(MANUFACTURER):
      dispatch(setSyncConnectorManufacturerData(result));
      break;
    case lowerCase(CATEGORY):
      let arr = formCategoryTreeData(result.data);
      if (filters && filters.filters && filters.filters.name) {
        arr = filterTreeData(arr, filters.filters.name, 'name');
      }
      dispatch(setSyncConnectorCategoryData(arr));
      dispatch(setOriginCategoryData(formCategoryTreeData(result.data)));
      break;
    case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
      dispatch(setSyncConnectorLaborCategoryData(result.data));
      break;
    case lowerCase(ITEM_TYPE).split(' ').join('-'):
      dispatch(setSyncConnectorItemTypeData(result.data));
      break;
    case lowerCase(LOGS):
      dispatch(setSyncConnectorLogsData(result));
      break;
    case lowerCase(WAREHOUSES):
      dispatch(setWarehouseData(result.data));
      break;
    default:
      dispatch(setSyncConnectorDistributorData(result.data));
      break;
  }
};

const switchDropdownSetFunction = (tab, dispatch, result) => {
  switch (tab) {
    case lowerCase(DISTRIBUTOR):
      dispatch(setSyncConnectorDistributorDropdownData(result.data));
      break;
    case lowerCase(MANUFACTURER):
      dispatch(setSyncConnectorManufacturerDropdownData(result.data));
      break;
    case lowerCase(CATEGORY):
      dispatch(setSyncConnectorCategoryDropdownData(result.data));
      break;
    case lowerCase(LABOR_CATEGORY).split(' ').join('-'):
      dispatch(setSyncConnectorLaborCategoryDropdownData(result.data));
      break;
    case lowerCase(ITEM_TYPE).split(' ').join('-'):
      dispatch(setSyncConnectorItemTypeDropdownData(result.data));
      break;
    default:
      break;
  }
};

const switchSetFunction = (type, tab, dispatch, result, filters) => {
  switch (type) {
    case INTEGRATION_LIST:
      switchListSetFunction(tab, dispatch, result, filters);
      break;
    case INTGERATION_DROPDOWN_LIST:
      switchDropdownSetFunction(tab, dispatch, result);
      break;
    default:
      break;
  }
};

export const getSyncConnectorIntegrationsData =
  (tab, id, fromRef, filters) => (dispatch) => {
    dispatch(changeApiLoader(true));
    let successFn = function (result) {
      if (tab === lowerCase(DISTRIBUTOR)) {
        result = {
          ...result,
          data: { ...result.data, ...filters },
        };
      } else {
        result = { ...result, ...filters };
      }
      switchSetFunction(INTEGRATION_LIST, tab, dispatch, result, filters);
      dispatch(changeApiLoader(false));
    };
    let errorFn = function () {
      dispatch(changeApiLoader(false));
    };
    if (
      tab === lowerCase(LOGS) ||
      tab === lowerCase(MANUFACTURER) ||
      tab === lowerCase(DISTRIBUTOR) ||
      tab === lowerCase(WAREHOUSES)
    ) {
      postAPI(
        interpolate(switchUrls(INTEGRATION_LIST, tab), [id]),
        filters,
        successFn,
        errorFn
      );
    } else {
      getAPI(
        interpolate(switchUrls(INTEGRATION_LIST, tab), [id]),
        successFn,
        errorFn
      );
    }
  };

export const getSyncConnectorIntegrationsDropdownData =
  (tab, id, fromRef, intDataFilters) => (dispatch) => {
    dispatch(changeApiLoader(true));
    let successFn = function (result) {
      if (fromRef === 'onTabChange') {
        dispatch(
          getSyncConnectorIntegrationsData(tab, id, undefined, {
            filters: intDataFilters,
          })
        );
      } else {
        dispatch(changeApiLoader(false));
      }
      switchSetFunction(INTGERATION_DROPDOWN_LIST, tab, dispatch, result);
    };
    let errorFn = function () {
      dispatch(changeApiLoader(false));
    };
    getAPI(
      interpolate(switchUrls(INTGERATION_DROPDOWN_LIST, tab), [id]),
      successFn,
      errorFn
    );
  };

export const getGeneralTabData = (id, itemTypeId) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(changeApiLoader(false));
    dispatch(setSyncConnectorGeneralData(result.data));
  };
  let errorFn = function (error) {
    dispatch(changeApiLoader(false));
  };
  getAPI(
    interpolate(INTEGRATION_GENERAL_LIST, [id, itemTypeId]),
    successFn,
    errorFn
  );
};

export const getGeneralEditFieldTableColumnData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(changeApiLoader(false));
    dispatch(setGeneralEditFieldTableColumnData(result.data));
  };
  let errorFn = function (error) {
    dispatch(changeApiLoader(false));
    console.log('error', error);
  };
  getAPI(
    interpolate(INTEGRATION_GENERAL_EDIT_FIELD_TABLE_COLUMN_DATA, [id]),
    successFn,
    errorFn
  );
};

const netsuiteItemTypeData = (data) => {
  return data.map((item) => {
    if (item.item_type_code === 'inventoryitem') {
      return { ...item, selectable: true };
    } else {
      return {
        ...item,
        selectable: false,
        children: [
          {
            item_type_code: `${item.item_type_code}-purchase`,
            item_type_name: `${item.item_type_name} for Purchase`,
          },
          {
            item_type_code: `${item.item_type_code}-sale`,
            item_type_name: `${item.item_type_name} for Sale`,
          },
          {
            item_type_code: `${item.item_type_code}-resale`,
            item_type_name: `${item.item_type_name} for Resale`,
          },
        ],
      };
    }
  });
};

export const getGeneralEditTargetData =
  (id, field_name, typeid, gdObj) => (dispatch) => {
    dispatch(changeApiLoader(true));
    let successFn = function (result) {
      dispatch(changeApiLoader(false));
      if (field_name === 'itemtype' && parseInt(typeid) === 8) {
        dispatch(setGeneralEditTargetData(netsuiteItemTypeData(result.data)));
      } else {
        dispatch(setGeneralEditTargetData(result.data));
      }
    };
    let errorFn = function (error) {
      dispatch(changeApiLoader(false));
      console.log('error', error);
    };
    const url =
      field_name === 'ns_primary_purchase_unit' ||
      field_name === 'ns_primary_sales_unit' ||
      field_name === 'ns_primary_stock_unit'
        ? interpolate(INTEGRATION_GENERAL_EDIT_TARGET_UNIT_TYPE, [
            id,
            gdObj['ns_unit_type'].default_value,
            field_name,
          ])
        : interpolate(INTEGRATION_GENERAL_EDIT_TARGET_DATA, [id, field_name]);
    getAPI(url, successFn, errorFn);
  };

function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].items = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id && map.hasOwnProperty(node.parent_id)) {
      list[map[node.parent_id]].items.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export const getGeneralEditSalesTaxClassificationData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    const { data } = result;
    let treeData = list_to_tree(data);
    dispatch(setGeneralEditSalesTaxClassificationData(treeData));
    dispatch(changeApiLoader(false));
  };
  let errorFn = function (error) {
    console.log('errorFn', error);
    dispatch(changeApiLoader(false));
  };
  getAPI(
    interpolate(INTEGRATION_GENERAL_EDIT_SALES_TAX_CLASSIFICATION_LIST, [id]),
    successFn,
    errorFn
  );
};

export const getSystemOptionsData = (id, loader) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    if (Array.isArray(result.data) && result.data.length > 0) {
      dispatch(setSystemOptionsData(result.data[0]));
    }
    if (!loader) {
      dispatch(changeApiLoader(false));
    }
  };
  let errorFn = function (error) {
    console.log('errorFn', error);
    dispatch(changeApiLoader(false));
  };
  getAPI(interpolate(INTEGRATION_SYSTEM_OPTIONS, [id]), successFn, errorFn);
};

export const getTargetInfoData = (id, changeLoaderCheck) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(setTargetInfo(result.data));
    if (changeLoaderCheck) {
      dispatch(changeApiLoader(false));
    }
  };
  let errorFn = function (error) {
    console.log('errorFn', error);
    dispatch(changeApiLoader(false));
  };
  getAPI(interpolate(INTEGRATION_TARGET_INFO, [id]), successFn, errorFn);
};

export const getManufacturerExpandedData = (code) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(setManufacturerExpandedData({ code, data: result.data }));
    dispatch(changeApiLoader(false));
  };
  let errorFn = function (error) {
    console.log('error', error);
    dispatch(changeApiLoader(false));
  };
  postAPI(INTEGRATION_MANUFACTURER_EXPANDED_LIST, { code }, successFn, errorFn);
};

export const getSubsidiaryList = (id) => (dispatch) => {
  let successFn = (result) => {
    dispatch(setSubsidiaryList(result.data));
  };
  let errorFn = (error) => {
    console.log('error subsidiary list', error);
  };
  getAPI(
    interpolate(INTEGRATION_SYSTEM_OPTIONS_SUBSIDIARY_LIST, [id]),
    successFn,
    errorFn
  );
};

export const {
  setTargetInfo,
  setSystemOptionsData,
  setSubsidiaryList,
  setSyncConnectorGeneralData,
  setSyncConnectorDistributorData,
  setSyncConnectorDistributorDropdownData,
  setSyncConnectorManufacturerData,
  setManufacturerExpandedData,
  setSyncConnectorManufacturerDropdownData,
  setOriginCategoryData,
  setSyncConnectorCategoryData,
  setSyncConnectorCategoryDropdownData,
  setSyncConnectorLaborCategoryData,
  setSyncConnectorLaborCategoryDropdownData,
  setSyncConnectorItemTypeData,
  setSyncConnectorItemTypeDropdownData,
  setGeneralEditFieldTableColumnData,
  setGeneralEditTargetData,
  setGeneralEditSalesTaxClassificationData,
  setSyncConnectorLogsData,
  setWarehouseData,
} = systemSyncConnectorIntegrationSlice.actions;

export default systemSyncConnectorIntegrationSlice.reducer;
