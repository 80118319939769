import AppBase from '../../base/AppBase';
import { Card, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import AboutTab from './aboutTab';
import SyncConnectorManagement from './syncConnectorTab';
import APIKeyManagement from './apiKeys';
import GeneralSystem from './generalTab';
import { useNavigate, useParams } from 'react-router';

const { Title } = Typography;

function SystemConfiguration() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeKey, setActivekey] = useState(tab);
  useEffect(() => {
    setActivekey(tab);
  }, [tab]);

  return (
    <AppBase>
      <Title className={'page-title'}>System Configuration</Title>
      <Tabs
        type='card'
        className={'system-config-tabs'}
        activeKey={activeKey}
        onChange={(key) => {
          navigate(`/system/${key}`);
        }}
        items={[
          {
            key: 'general',
            label: 'General',
            children: <GeneralSystem />,
          },
          {
            key: 'sync-connectors',
            label: 'Sync Connectors',
            children: (
              <Card>
                <SyncConnectorManagement />
              </Card>
            ),
          },
          {
            key: 'api-keys',
            label: 'API Keys',
            children: (
              <Card>
                <APIKeyManagement />
              </Card>
            ),
          },
          {
            key: 'about',
            label: 'About',
            children: (
              <Card>
                <AboutTab />
              </Card>
            ),
          },
        ]}
      />
    </AppBase>
  );
}

export default SystemConfiguration;
